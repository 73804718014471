






























































import Vue from 'vue';

export default Vue.extend({
  name: 'App',

  data: () => ({
    color: "pink darken-4",
    drawer: false,
    group: null,
  }),

  watch: {
    group () {
      this.drawer = false
    },
  },
});
