































import Vue from 'vue'
import axios from 'axios'
import Photo from '@/components/Photo.vue'
import Acknowledgement from '@/components/Acknowledgement.vue'

export default Vue.extend({
    data: () => ({
        name: '',
        email: '',
        phone: '',
        message: '',
        sent: false
    }),
    components: {
        Photo,
        Acknowledgement
    },
    methods: {
        send : function () {
            
            axios.post(`https://xpkvqnnb0m.execute-api.ap-southeast-2.amazonaws.com/prod/contact`, { 
                name: this.name,
                email: this.email,
                phone: this.phone,
                message: this.message
            },
            {
                headers: {}
            }).then(response => { 
                this.sent = true
                console.log(response)
            })

            
        }
    }
})
