












import Vue from 'vue'

export default Vue.extend({
    props: ['image'],
    computed: {
        src() { 
            return `/img/Fiona_Taylor_WEB-${this.image}.jpg`
        },
        img_width(): number {
            return this.$vuetify.breakpoint.width / 2
        },
        img_height(): number {
            return this.$vuetify.breakpoint.height * 0.8
        },
        img_dims() {
            const w = this.$vuetify.breakpoint.width / 2
            const h = this.$vuetify.breakpoint.height * 0.75
            if(h > w * 1.5) {
            return [(w * 1.5).toFixed(0), w.toFixed(0)]
            } else {
            return [h.toFixed(0), (h * .666).toFixed(0)]
            }
        }
    }
})
