
























import Vue from 'vue'
import Photo from '@/components/Photo.vue'
import Acknowledgement from '@/components/Acknowledgement.vue'

export default Vue.extend({
  components: {
    Photo,
    Acknowledgement
  },
})
