






















  import Vue from 'vue'
  import Photo from '@/components/Photo.vue'
  import Acknowledgement from '@/components/Acknowledgement.vue'
  import Recommendation from '@/components/Recommendation.vue'

  export default Vue.extend({
      components: {
          Photo,
          Acknowledgement,
          Recommendation
      },
      data: () => ({
          books: [
              { date: 'March 27, 2022', title: 'The Tricky Art of Forgiveness', author: 'Meredith Jaffe', publisher: 'Harper Collins', published: 'Mar 2022', image: 'the_tricky_art_of_forgiveness.jpg', text: [
                  `This beautiful novel has a difficult central story but her beautiful character development and light touch made this a quick read. I devoured it over a matter of days (busy days I might add!). The composition of the chapters, alternating between Diana's life before and now, made for engaging reading. I'm close in age to the main protagonist, also approaching my 30th wedding anniversary, and I found the story (while not like my own life) completely believable.`,
                  `Diana is in the middle of planning a combined celebration for her husband's 60th birthday and their 30th wedding anniversary when she finds a scrap of paper with the words 'I forgive you.' Suddenly everything in her life is turned on its head as she needs to address the past to fix their future together. This is a heart-warming and often funny story and the final chapters had me in tears. A celebration of family, love, loss, and a woman rediscovering herself after putting aside her own needs for too long. `,
                  `Of added interest is a playlist of wonderful jazz classics and a QR code so you can find the playlist on Spotify! I recommend this charming new novel from the author of <i>The Dressmakers of Yarrandarrah Prison</i>.`
              ]},
              { date: 'March 1, 2022', title: 'The Competition', author: 'Katherine Collette', publisher: 'Text Publishing', published: 'Feb 2022', image: 'the_competition.jpg', text: [
                  `This is a charming book full of endearing characters and genuine laugh out loud moments. I found it an easy read due to the fast and engaging pace. The story sweeps the reader into the competitive world of ‘SpeechMakers’ (loosely based on Toastmasters) and the three days of their National Championship.`,
                  `Swapping between the two main protagonists, prickly finalist Frances and her possibly ex-mentor and co-finalist Keith, works extremely well. Their reasons for wanting to win may be different but ultimately their need to atone for their past actions makes them more alike than they realise.`,
                  `Collette is brilliant at creating every day, engaging characters with recognisable quirks and flaws. The minor characters are equally strong which makes for an enjoyable read. This is a story about facing your fears and finding your voice. It reminds me (very positively!) of ‘The Castle’ and ‘Strictly Ballroom’. I hope it is optioned because it would be a thoroughly entertaining movie!`
              ]},
              { date: 'February 10, 2022', title: 'The Torrent', author: 'Dinuka McKenzie', publisher: 'Harper Collins', published: 'Feb 2022', image: 'the_torrent.jpg', text: [
                  `Dinuka McKenzie has written an impressive debut with believable characters and a clever mystery that links two seemingly unconnected cases. Protagonist detective, Kate Miles, is heavily pregnant and working on her final case before maternity leave when she is asked to give a simple review of a cold case. She is quickly pulled into something more complicated, and the reader is taken along for a fast-paced, well-crafted ride.`,
                  `It is easy to see why Dinuka McKenzie won the Banjo Prize in 2020 for this crime procedural with an engaging human element. Her main character is a triumph – Kate is a working mum, juggling career and home, while handling swollen feet, work politics and the impending birth of her second child. It is obvious Dinuka has done her research as the police scenes are excellent. The familiarity of a small town was perfectly captured, and all the characters are fleshed out, not just the main ones.`,
                  `I love a clever, fast-paced plot, and this did not disappoint. I finished it in two days! Dinuka McKenzie is an exciting new voice in the Australian crime genre, and I look forward to the next in the series. At the end of The Torrent is a sneak peek of her next book, Taken, and it promises to be just as good. Highly recommended read for fans of Dervla McTiernan and Jane Harper.`
              ]},
              { date: 'January 24, 2022', title: 'The Spy’s Wife', author: 'Fiona McIntosh', publisher: 'Penguin Australia', published: 'Nov 2021', image: 'the_spys_wife.jpg', text: [
                  `Fiona McIntosh is a prolific and talented storyteller. Her latest story The Spy’s Wife is a perfect mix of spy thriller and historical fiction. Set in pre-war 1936, readers are transported from the beautiful Yorkshire dales to Hitler’s new, and sometimes dangerous, Germany.`,
                  `We follow stationmaster’s daughter Evie, who volunteers to spy for England to save the life of the man she loves. McIntosh cleverly approaches the role of women as spies during WWII from a fresh angle, showing how ordinary people can be caught up in the web of political espionage. As always, her research is meticulous, which helps take her writing to the next level. The reader is immediately immersed in a world of intrigue steeped in historical fact, with real life characters and moments in history referenced throughout the story. The characters are both engaging and believable and, like Evie, the reader isn't always sure who to trust.`,
                  `The author's attention to detail is apparent throughout the book - in her descriptions of the English countryside, the clothes her characters wear and what life was really like for the German people as their government readied itself for war. I raced through the final chapters alongside Evie as she tries to outwit her enemies and prove that Hitler is planning to go to war with England. This is Fiona McIntosh's best book to date, and a thrilling read. I would highly recommend it to anyone who loves historical fiction or domestic suspense.`
              ]},
              { date: 'January 8, 2022', title: 'The Mistake', author: 'Katie McMahon', publisher: 'Bonnier Echo', published: 'Mar 2021', image: 'the_mistake.jpg', text: [
                  `My first read for 2022 is a strong debut novel from Tasmanian writer Katie McMahon. I finished it in 2 days!`,
                  `<i>The Mistake</i> details the lives of sisters Kate and Bec and the fallout from a mistake that has dangerous consequences. McMahon is a GP, and this shows in the strong medical scenes scattered throughout the story. Her characters are strong, engaging and their dialogue is believable and occasionally witty. `,
                  `The story is told interestingly in alternating first person POV (Kate) and third person limited POV (Bec). This allows for the suspense in the second half to build and allows for the plot twist. A slow burn, it seemed to lag a little in the middle, but the end more than made up for it. I look forward to reading more from this author.`,
                  `I’d recommend <i>The Mistake</i> to anyone who loves Sally Hepworth or Liane Moriarty.`
              ]},
              { date: 'December 29, 2021', title: 'The Judge’s List', author: 'John Grisham', publisher: 'Hodder & Stoughton', published: 'Oct 2021', image: 'the_judges_list.jpg', text: [
                  `Buying a Grisham for Christmas is a bit of a tradition in our house. They make for easy reading and generally satisfy even the fussiest reader. Perfect for those days between Boxing Day and New Year’s.`,
                  `<i>The Judge’s List</i> follows on from <i>The Whistler</i> and again has lawyer and judiciary investigator Lacy Stoltz as the main protagonist.`,
                  `The story starts with a bang - there’s a serial killer nobody knows about and guess what? He’s a judge (this is not a spoiler). As is usual with Grisham’s books, the story is fast paced, heavy on description and light on character development. Why does anyone do what they do in this story? Who cares? Next victim!`,
                  `“The Judge’s List” ticks a lot of boxes - psychopathic serial killer, earnest investigator with personal problems, an ever-growing list of victims and some technology wizardry that may or may not be possible IRL.`,
                  `If you love John Grisham’s other books, you won’t be disappointed, but I would have loved a bit more character development, so I cared who lived or died.`,
              ]},
          ],
      }),
})
