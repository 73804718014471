














import Vue from 'vue'
import axios from 'axios'
import Photo from '@/components/Photo.vue'

export default Vue.extend({
    data: () => ({
    }),
    components: {
        Photo
    },
    methods: {
    }
})
